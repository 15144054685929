import { useState } from "react";
import {
  Container,
  Image,
  Heading,
  Paragraph,
  BackgroundFade,
  CloseImage,
} from "./components";

const a1 = process.env.PUBLIC_URL + "/images/a1.png";
const a2 = process.env.PUBLIC_URL + "/images/a2.png";
const a3 = process.env.PUBLIC_URL + "/images/a3.png";
const logo = process.env.PUBLIC_URL + "/images/logo.png";

function App() {
  const [image, setImage] = useState<string>();

  return (
    <>
      {image && (
        <div onClick={() => setImage(undefined)}>
          <BackgroundFade />
          <CloseImage>X</CloseImage>
          <Image src={image} isExpanded />
        </div>
      )}
      <Container
        css={{
          textAlign: "center",
        }}
      >
        <Heading>
          ambt
          <Image
            src={logo}
            css={{
              width: "30px",
              verticalAlign: "baseline",
              marginLeft: "8px",
            }}
          />
        </Heading>
        creative development.
      </Container>
      <Image src={a1} css={{ width: "100%" }} onClick={() => setImage(a1)} />
      <Container>
        <Paragraph>
          Ambt{" "}
          <span style={{ fontStyle: "italic" }}>(pronounced 'ambient')</span> is
          the name for development projects by Mia Williams-Johnson, based in
          Bristol, UK.
        </Paragraph>
        <Paragraph>
          The current focus, <strong>Shutter-bugged</strong> (working title) is
          a top down action RPG set in a vast entirely hand modelled world (such
          as dioramas and model railways). The game is split between a relaxing,
          social media sightseeing tour with a heavy emphasis on story, and
          battles on the internet - where the actions the player takes in the
          real world will affect their powers online.
        </Paragraph>
      </Container>
      <Image src={a3} css={{ width: "100%" }} onClick={() => setImage(a3)} />
      <Container>
        <Paragraph>
          The game world uses a unique mix of 2D and 3D game development
          techniques - developed over years of research - to allow a seamless
          modelled world that utilises lighting, shadows, depth of field, and
          more to create an immersive world with a full day/night cycle.
        </Paragraph>
        <Paragraph>
          All projects have a focus on aesthetics, with Ambt's signature style
          being a unique mix of photography, miniature scale, and digital
          artwork. The idea for the style came from a love of models as I grew
          up, and it evolved from something based around a hobby to a unique
          approach that I found could be applied to many different creative
          projects.
        </Paragraph>
      </Container>
      <Image src={a2} css={{ width: "100%" }} onClick={() => setImage(a2)} />
      <Container>
        <Paragraph>
          Have any questions, comments, concerns, or anything else? Please send
          an email to <strong>mia [at] getambt [dot] co [dot] uk</strong> and
          I'll get back to you as soon as I can. Please note development is
          incredibly slow since life blessed me with two children and a much
          more rewarding job than when the project started, but progress is
          still being made. Stay tuned!
        </Paragraph>
      </Container>
      <Container>
        <Paragraph
          css={{ fontSize: "12px", color: "DarkGray", padding: "0 20px 40px" }}
        >
          ambt, getambt, and the ambt logo are © ambt, 2023. All game footage is
          © ambt, 2023. Footage is from development and not representational of
          a final product.
        </Paragraph>
      </Container>
    </>
  );
}

export default App;
