import { styled } from "@stitches/react";

export const Container = styled("div", {
  width: "100%",
  maxWidth: "1200px",
  marginLeft: "auto",
  marginRight: "auto",
  padding: "20px",
  boxSizing: "border-box",
});

export const Heading = styled("h1", {
  fontWeight: "500",
  fontSize: "6rem",
  margin: "unset",
});

export const Paragraph = styled("p", {
  textAlign: "center",
  margin: "unset",
  "&:not(:last-child)": { marginBottom: "12px" },
});

export const BackgroundFade = styled("div", {
  position: "fixed",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  width: "100%",
  height: "100%",
  zIndex: 5,
  top: 0,
  left: 0,
  cursor: "pointer",
});

export const Image = styled("img", {
  height: "auto",
  cursor: "pointer",
  variants: {
    isExpanded: {
      true: {
        position: "fixed",
        top: "50%",
        left: "50%",
        maxWidth: "90vw",
        transform: "translate(-50%, -50%)",
        zIndex: "10",
      },
    },
  },
});

export const CloseImage = styled("div", {
  color: "white",
  fontFamily: "arial",
  fontSize: "30px",
  fontWeight: "700",
  position: "fixed",
  top: "0",
  right: "0",
  padding: "20px",
  zIndex: "10",
  cursor: "pointer",
});
